import React from "react";
import { useAuth } from '../hooks/useAuth'

export const Header = () => {
  const { user } = useAuth();
  const get_actual_header = () => {
    if (user) {
      return (
        <>
          <a className='text-2xl leading-[1.175rem] self-end' href='/me'>profile</a>
          <a className='text-2xl leading-[1.175rem] self-end' href='/logout'>logout</a>
        </>
      );
    } else {
      return (
        <>
          <a className='text-2xl leading-[1.175rem] self-end' href='/login'>login</a>
          <a className='text-2xl leading-[1.175rem] self-end' href='/signup'>sign up</a>
        </>
      );
    }
  };
  return (
    <div className='w-full'>
      <nav 
        className='max-w-screen-xl mx-auto border-b-2 border-ctp-mauve transition hover:border-ctp-pink duration-1000'
      >
        <ul className='flex justify-between px-12 py-6'>
          <li className='flex'>
            <a 
              className='text-3xl font-bold text-gradient from-ctp-red to-ctp-mauve leading-6 self-end'
              href='/'
            >
              <h1>ALT/SP3AK</h1>
            </a>
          </li>
          <li className='flex gap-x-12'>
            {get_actual_header()}
          </li>
        </ul>
      </nav>
    </div>
    );
};

export default Header;
