import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

export const Logout = () => {
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      logout();
    } else {
      navigate('/login');
    }
  }, [])

  return (
    <>
    </>
  );
};

export default Logout;
