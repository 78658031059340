export async function login_request(nickname, password) {
  const encoded_nick = encodeURIComponent(nickname);
  const encoded_pass = encodeURIComponent(password);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: JSON.stringify(
      `grant_type=&username=${encoded_nick}&password=${encoded_pass}&scope=`
    ),
  };
  const response = await fetch('/api/login', requestOptions);
  return response
};

export async function signup(nickname, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ nickname: nickname, password: password }),
  };

  const response = await fetch('/api/register', requestOptions);
  return response
};

export async function updateUser(token, update) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(update),
  };

  const response = await fetch('/api/me', requestOptions);
  return response;
};
