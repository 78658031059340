import React from 'react'

import PhotoGrid from '../components/PhotoGrid'

export const Blog = ({wrapRef}) => {
  return (
    <>
    <PhotoGrid wrapRef={wrapRef}/>
    </>
  );
  (
    <>
      <span className='text-lg'>камин сун</span>
      <img className='w-96' src='https://i.imgur.com/tiJUhHj.jpeg' />
    </>
  )

}
export default Blog
