import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useAuth } from '../hooks/useAuth'

import axios from 'axios';

const PhotoGrid = ({ wrapRef }) => {
  const isCalledRef = useRef(false);
  const imgRef = useRef();
  const [photos, setPhotos] = useState([]);
  const [page, setPage] = useState(1);
  const [err, setErr] = useState()
  const [eof, setEof] = useState(false)
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const fetchPhotos = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/pics?page=${page}`,
        {headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.access_token,
        }}
      )
      setPage(prev=>(prev+1))
      console.log('ok', response)
      //const reader = new window.FileReader();
      //reader.readAsDataURL(response.data); 
      //reader.onload = ()=>{
      //  setPhotos(prevPhotos => [...prevPhotos, reader.result])
      //}
      setPhotos(prevPhotos => [...prevPhotos, ...response.data])
      return response
    } catch (err) {
      setEof(true)
      const a = await err.response?.data.text
      if (a) {
        console.log(a)
        setErr(JSON.parse(a).detail)
        setTimeout(()=>{setErr('')}, 7000)
      } else {
        console.error(err.message)
      }
    } finally {
      setLoading(false);
    }
  }, [page]);

  const handleScroll = useCallback(() => {
    if (wrapRef.current.offsetHeight + wrapRef.current.scrollTop + 200 < wrapRef.current.scrollHeight || loading) {
      //console.log(wrapRef.current, wrapRef.current.offsetHeight ,wrapRef.current.scrollTop, wrapRef.current.scrollHeight)
      return;
    }
    if (eof) return
    fetchPhotos();
  }, [loading, eof, fetchPhotos]);

  useEffect(() => {
    wrapRef.current.addEventListener('scroll', handleScroll);
    return () => wrapRef.current.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const checkContentHeight = () => {
      const contentHeight = imgRef.current.scrollHeight
      const viewportHeight = window.innerHeight;
      if (contentHeight <= viewportHeight && !loading) {
        if (!isCalledRef.current) {
          isCalledRef.current = true;
          return
        }
        if (eof) return
        fetchPhotos();
      }
    };
    checkContentHeight();
}, [photos, loading]);
  useEffect(()=>{
    fetchPhotos();
  }, [])

  return (
    <div>
      <div className='fixed bg-green-500 px-10 text-bold text-3xl text-red-500'>
        {err && 'no more pixes u addicted faggot'}
      </div>
      <div ref={imgRef} className='flex flex-col'>
        {photos.map((photo, key) => (
          <img key={key} src={photo.url} alt='cool pic' style={{ width: '600px', margin: '10px' }} />
        ))}
      </div>
      {eof && <p>no more funny pix</p>}
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default PhotoGrid;
