import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { ErrorMessage } from "./ErrorMessage"
import { useAuth } from '../hooks/useAuth'
import { login_request } from '../utils/userService'

export const LoginForm = () => {
    const { login, user } = useAuth();
    const [nickname, setNickname] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    

    const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await login_request(nickname, password);
      const data = await response.json();
      if (response.ok) {
        login(data);
      } else {
        setErrorMessage(data.detail);
      };
    };
    

    return (
      <div className='flex flex-col gap-2 rounded-md bg-gradient-to-br from-violet-400 to-pink-200 py-4 px-8'>
        <form onSubmit={handleSubmit} className='flex flex-col gap-4 mx-auto'>
          <h2 className='text-white font-bold text-3xl'>Зайти</h2>
          <input
            placeholder='логин'
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            required
          />
          <input
            type='password'
            placeholder='пароль'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className='send-btn mt-2 rounded-md' type='submit' >
            Подключиться
          </button>
        </form>
        <div>
          <ErrorMessage message={errorMessage} />
        </div>
      </div>
    );
};

export default LoginForm;
