import Header from './Header'

export const StdLayout = ({component}) => {
  return (
    <>
      <div 
        className='w-full h-dvh bg-gradient-to-b from-ctp-base to-ctp-crust text-ctp-text'
      >
        <Header />
        <div className='max-w-screen-xl mx-auto px-12 py-8'>
          {component}
        </div>
      </div>
    </>
  );
}

export default StdLayout;
