import Header from './Header'

export const StdLayout = ({component}) => {
  return (
    <>
      <div 
        className='w-full h-dvh bg-gradient-to-br from-ctp-base to-ctp-crust text-ctp-text'
      >
        {component}
      </div>
    </>
  );
}

export default StdLayout;
