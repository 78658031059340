import React, { useRef, useEffect } from "react";

export const SideBar = ({setSelectedSection}) => {
  const buttonsParent = useRef();
  const sectionMap = {'hernya': 0, 'Chat': 1, 'Kliker': 2, 'Profile': 3}
  const prevSection = useRef();
  const setSelectedSectionWrapper = (section) => {
    setSelectedSection(section);
    if (prevSection.current) {
      buttonsParent.current.children[sectionMap[prevSection.current]].classList.remove("text-ctp-mauve")
      buttonsParent.current.children[sectionMap[prevSection.current]].classList.remove("scale-95")
    }
  
    if (buttonsParent) {
    buttonsParent.current.children[sectionMap[section]].classList.add("text-ctp-mauve")
    buttonsParent.current.children[sectionMap[section]].classList.add("scale-95")
    }
    prevSection.current = section;
  }

  useEffect(()=>{
    buttonsParent.current.children[sectionMap['hernya']].classList.add("text-ctp-mauve")
    buttonsParent.current.children[sectionMap['hernya']].classList.add("scale-95")
    prevSection.current = 'hernya'
  }, [])

  return (
    <>
      <div className="flex flex-col w-1/6 min-w-52 w-52 h-full gap-4 p-4 bg-ctp-crust">
        <div className='flex flex-col py-4 gap-2 text-center border-ctp-mauve'>
          <span className='block text-md mx-auto text-xl text-gradient from-ctp-red to-ctp-mauve leading-6 select-none'>Сaйт В(о_О)JlШебниЦ</span>
        </div>
        <div className='flex flex-col gap-2 h-full' ref={buttonsParent}>
          <div onClick={()=>{setSelectedSectionWrapper ('hernya')}} className='sidebar-item font-light'>
            Hernya
          </div>
          <div onClick={()=>{setSelectedSectionWrapper ('Chat')}} className='sidebar-item font-light'>
            Chat
          </div>
          <div onClick={()=>{setSelectedSectionWrapper ('Kliker')}} className='sidebar-item font-light'>
            Kliker:D
          </div>

          <div onClick={()=>{setSelectedSectionWrapper ('Profile')}} className='sidebar-item mt-auto font-light'>
            Profile
          </div>
          <div onClick={()=>{setSelectedSection('Logout')}} className='sidebar-item font-light'>
            Logout
          </div>
        </div>
      </div>
    </>
  )

}

export default SideBar;
