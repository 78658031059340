import React, { useContext, useState } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import MainPage from './views/MainPage'
import Login from './views/Login'
import Logout from './views/Logout'
import Signup from './views/Signup'
import Profile from './views/Profile'
import StdLayout from './components/StdLayout'

import { PrivateRoutes } from './PrivateRoute'
import { AuthProvider } from './hooks/useAuth'


function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path='/' element={ <StdLayout component={<MainPage/>}/> }/>
        <Route path='/login' element={ <StdLayout component={<Login/>}/> }/>
        <Route path='/signup' element={ <StdLayout component={<Signup/>}/> }/>
        <Route element={<PrivateRoutes />}>
          <Route path='/me' element={ <StdLayout component={<Profile/>}/> }/>
          <Route path='/logout' element={ <Logout/> }/>
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default App;
