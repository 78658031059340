import { useEffect, useState, useReducer, useRef } from 'react';

import Shop from '../components/Shop'


export function reducer(state, action) {
  let data = state.data
  switch(action.type) {
    case 'click':
      return {
        data: {
          ...data,
          score: data.score + data.clickPower,
        },
        error: null
      };
    case 'buy':
      if (data.score < action.cost) {
        return {
          data,
          error: 'not enough credit >_<'
        }
      }
      if (action.what == 'click') {
        return {
          data: {
            ...data,
            score: data.score - action.cost,
            clickPower: data.bought.click + 2,
            bought: {
              ...data.bought,
              click: data.bought.click + 1,
            },
          },
          error: null
        }
      }
      if (action.what == 'first') {
        return {
          data: {
            ...data,
            score: data.score - action.cost,
            bought: {
              ...data.bought,
              first: data.bought.first + 1,
            }
          },
          error: null
        }
      }
    case 'tick':
      return {
        data: {
          ...data,
          score: data.score + data.bought.first
        },
        error: null
      }

    default:
      return state
  }
}

export const ClickerPage = ({ state, dispatch }) => {
  const tickInterval = useRef();
  const [error, setError] = useState('');

  useEffect(()=>{
    state.error && setError(state.error);
    setTimeout(()=>{setError('')}, 10000)
  }, [state.error])

  useEffect(()=>{
    if (state.data.bought.first > 0) {
      if (tickInterval) {
        clearInterval(tickInterval.current)
      }
      tickInterval.current = setInterval(()=>dispatch({ type: 'tick' }), 1000)
    }
  }, [state.data.bought])

  useEffect(()=>{
    return () => {
      clearInterval(tickInterval.current)
    }
  }, [])


  return (
    <div className='w-96 pt-10 flex flex-col gap-4'>
      <div>{error}</div>
      <div className='flex justify-between'>
        <span>score: {state.data.score}</span>
        <span>click powerr: {state.data.clickPower}</span>
        <span>in sec: {state.data.bought.first}</span>
      </div>
      <button className="w-96 send-btn" onClick={() => {
        dispatch({ type: 'click' })
      }}>
          click me
      </button>
      <Shop state={state} dispatch={dispatch} setError={setError}/>
    </div>
  );
}
