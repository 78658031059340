import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import Peer from "peerjs"; 

import { useAuth } from '../hooks/useAuth'


const getAudio = () => {
  return navigator.mediaDevices.getUserMedia({ audio: true, video: false })
}

let userId = '', myStream = null, peer = null, peers= [], receivedCalls = [];
let peerConfig ={
  iceServers: [
    {
      urls:[
        'stun:stun.1.google.com:19302',
        'stun:stun1.1.google.com:19302'
      ]
    }
  ]
}


export const VoiceChat = ({usersInVoice, socket}) => {
  const { user, logout } = useAuth();
  const [isJoined, setIsJoined] = useState(false);
  const [peerStatus, setPeerStatus] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const onReceiveAudioStream = (stream) =>{ 
    console.log("receiving an audio stream"); 
    const audio = document.createElement('audio');
    audio.srcObject = stream;
    audio.addEventListener('loadedmetadata', () => {
        audio.play()
    })
  }

  useEffect(()=>{
    if (isJoined) {
      socket.current.emit('joinVoice')
      getAudio().then(mystream=>{
        myStream = mystream;
        peer = new Peer(socket.current.id, peerConfig)
        console.log(peer);
	if (peer.destroyed) {
	  peer = new Peer(socket.current.id, peerConfig)
          console.log('new peer')
	}

        peer.on('call', (call)=>{
          console.log("call receiving")
          call.answer(mystream); 
          call.on('stream', (stream)=>{
              onReceiveAudioStream(stream); 
              receivedCalls.push(stream); 
              console.log('receivedcalls', receivedCalls)
          });
        });

        peer.on('open', (id) => {
          setPeerStatus(true);
          console.log("connected to peerserver");

          // won't call myself 
	  console.log('im', socket.current.id)
	  console.log('users', usersInVoice)
          const otherUsersInVoice = (usersInVoice).filter(x=>x[0] !== socket.current.id);  
	  console.log(otherUsersInVoice)

          peers = (otherUsersInVoice).map((u) => {
            //call everyone already present 
            var mediaConnection = peer.call(u[0], mystream); 
            console.log(`Calling ${u[1].nickname}`);

            const audio = document.createElement('audio');
            mediaConnection.on('stream', (stream)=>{
              console.log(`${u[1].nickname} picked up call`)
              audio.srcObject = stream
              audio.addEventListener('loadedmetadata', () => {
                audio.play()
              })
            });

            // if anyone closes media connection 
            mediaConnection.on('close',()=>{
                audio.remove();
            })
            return mediaConnection; 
          });
          console.log('peers', peers)
        });
      })
  } else {
    if (peer) {
      peer.disconnect();
    }
    setPeerStatus(false)}
  },[isJoined])

  const onJoinButton = async ()=>{
    if (isJoined) {
      console.log('isjoined leave voice')
      socket.current.emit('leaveVoice')
    }
    setIsJoined(!isJoined)
  }


  return (
    <>
      <h1 className='text-3xl mb-4 text-center'>Воис</h1>
      <div className='flex justify-between'>
	<p className='text-lg my-auto'>Соединение: {peerStatus ? '👌' : '❌'}</p>
        <button className='send-btn w-1/4' onClick={onJoinButton}>
          {isJoined ? 'leave' : 'join'}
        </button>
      </div>
      <div className='flex flex-col gap-4 mt-10 p-4 bg-ctp-base shadow-md rounded-md min-h-44'>
        {usersInVoice.map((user, idx)=>
          <div className='flex' key={idx}>
            <img 
              className='w-16 h-16 object-cover rounded-md'
              src={user[1].profile_pic}
              alt='just profile pic'
            />
            <div className='flex-1 ml-4 border-b-2 border-ctp-mauve'>
              <span className='block text-xl'>{user[1].nickname}</span> 
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VoiceChat
