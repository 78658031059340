import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { ErrorMessage } from "../components/ErrorMessage"
import { useAuth } from '../hooks/useAuth'
import { login_request } from '../utils/userService'

export const Login = () => {
    const { login, user } = useAuth();
    const navigate = useNavigate();
    const [nickname, setNickname] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    

    const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await login_request(nickname, password);
      const data = await response.json();
      if (response.ok) {
        login(data);
      } else {
        setErrorMessage(data.detail);
      };
    };
    

    return (
      <>
        <form onSubmit={handleSubmit} className='w-1/4 flex flex-col gap-4 mx-auto my-8'>
          <h2 className='text-center text-3xl'>Зайти</h2>
          <input
            placeholder='логин'
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            required
          />
          <input
            type='password'
            placeholder='пароль'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className='send-btn mt-2' type='submit' >
            Подключиться
          </button>
          <div>
            <ErrorMessage message={errorMessage} />
          </div>
        </form>
      </>
    );
};

export default Login;
