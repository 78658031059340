import { useEffect, useState, useReducer, useContext } from 'react';



function Shop({state, dispatch, setError}) {
  const [clickCost, setClickCost] = useState(10);
  const [firstCost, setFirstCost] = useState(10);

  useEffect(()=>{
    state.data.bought.first && setFirstCost(((state.data.bought.first+1)*2) ** 2)
  }, [state.data.bought.first])

  useEffect(()=>{
    state.data.bought.click && setClickCost(((state.data.bought.click+1)*2) ** 2)
  }, [state.data.bought.click])

  return (
  <div className='flex flex-col gap-4'>
    <span className='text-center'>SHOP</span>
    <button className='w-96 px-10 flex justify-between send-btn' onClick={()=>{
      dispatch({ type: 'buy', cost: clickCost, what: 'click'})
    }}>
      <span>click power +1</span>
      <span>{clickCost}</span>
    </button>
    <button className='w-96 px-10 flex justify-between send-btn' onClick={()=>{
      dispatch({ type: 'buy', cost: firstCost, what: 'first'})
    }}>
      <span>in sec +1</span>
      <span>{firstCost}</span>
    </button>
  </div>
  )
}
export default Shop;
