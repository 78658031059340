import React, { useEffect, useState, useCallback } from 'react';
import Peer from "peerjs"; 

import { useAuth } from '../hooks/useAuth'


const getAudio = () => {
  return navigator.mediaDevices.getUserMedia({ audio: true, video: false })
}
const getScreenVideo = () => {
  return navigator.mediaDevices.getDisplayMedia({  audio: true, video: true })
}

let peerOptions = {
  debug: 2,
  host: '/',
  path: 'peer',
  config: {
    iceServers: [
      { urls: 'turn:alts.fun:3478', username: 'test', credential: 'test123' },
      { urls: 'stun:freestun.net:3478' },
      { urls: 'turn:freestun.net:3478', username: 'free', credential: 'free' },
      {"url":"turn:relay1.expressturn.com:3478","username":"ef571W8ID1WGRKIXZ8","credential":"dT8rIL0MD8gOZZfQ"},
      {'url': 'stun:stun.l.google.com:19302'},
      {"url":"turn:global.relay.metered.ca:80","username":"29f02abf8757308f9e50a059","credential":"oTAQH2P6/aDVtFhx"},
      {"url":"turn:global.relay.metered.ca:80?transport=tcp","username":"29f02abf8757308f9e50a059","credential":"oTAQH2P6/aDVtFhx"},
      {"url":"turn:global.relay.metered.ca:443","username":"29f02abf8757308f9e50a059","credential":"oTAQH2P6/aDVtFhx"},
      {"url":"turns:global.relay.metered.ca:443?transport=tcp","username":"29f02abf8757308f9e50a059","credential":"oTAQH2P6/aDVtFhx"},
      {'url': 'stun:fr-turn8.xirsys.com'},
      { urls: [
          "turn:fr-turn8.xirsys.com:80?transport=udp",
          "turn:fr-turn8.xirsys.com:3478?transport=udp",
          "turn:fr-turn8.xirsys.com:80?transport=tcp",
          "turn:fr-turn8.xirsys.com:3478?transport=tcp",
          "turns:fr-turn8.xirsys.com:443?transport=tcp",
          "turns:fr-turn8.xirsys.com:5349?transport=tcp"
        ],
        username: "xJWi3ovYNRp9yP1dxvdnuptb9gG3FWoKfF-XK9GMaPkxB7KFxjacUK85SAKP4vVtAAAAAGdkZOFsYXR0ZXJuMzE=",
        credential: "7e8fe18e-be36-11ef-83a9-0242ac120004" },
    ]
  }
}

const peerCallOptions = {
  'constraints': {
    'mandatory': {
      'OfferToReceiveAudio': true,
      'OfferToReceiveVideo': true
    },
    offerToReceiveAudio: 1, offerToReceiveVideo: 1,
  }
}
const Video = ({ videoTrack, audioTrack}) => {
  const mediaStream = new MediaStream();
  mediaStream.addTrack(videoTrack);
  if (audioTrack) {
    mediaStream.addTrack(audioTrack)
  }
  //console.log(mediaStream.getTracks())

  const refVideo = useCallback(
    (node)=>{
      if (node) {
        node.srcObject = mediaStream;
        node.controls = true;
        node.addEventListener('loadedmetadata', () => {
          node.play()
        })
      }
    }, [videoTrack, audioTrack]);

  return (
    <div>
      <video ref={refVideo}/>
    </div>
  )
}


export const VoiceChat = ({
  usersInVoice, socket, isLoading, myStream,
  peer, peers, receivedCalls, peerStatus, setPeerStatus, isJoined, setIsJoined, otherVideos, setOtherVideos,
}) => {
  const { user, logout } = useAuth();
  const [isMuted, setIsMuted] = useState(false);
  const [myMeasureInterval, setMyMeasureInterval] = useState(0);
  const [volumeMap, setVolumeMap] = useState(new Map());
  const [isVideoEnabled, setIsVideoEnabled] = useState(false);


  const onReceiveAudioStream = (stream) =>{ 
    const audio = document.createElement('audio');
    audio.srcObject = stream;
    audio.addEventListener('loadedmetadata', () => {
      audio.play()
    })
  }

  const onReceiveVideoStream = (stream) =>{ 
    const mediaStream = new MediaStream();
    mediaStream.addTrack(stream.getVideoTracks()[0]);
    const video = document.createElement('video');

    video.srcObject = mediaStream;
    video.addEventListener('loadedmetadata', () => {
      video.play()
    })
    document.getElementById('videvo').appendChild(video)
  }

  const measureVolume = (id, stream) => {
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);

    analyser.smoothingTimeConstant = 0.5;
    analyser.fftSize = 1024;
    microphone.connect(analyser);

    const measure = setInterval(() => {
      if (!stream.active) {
        console.log('clearing meausuring', stream)
        clearInterval(measure);
      }
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      let sum = 0;
      for (let i = 0; i < array.length; i++) {
          sum += array[i];
      }
      const average = sum / array.length;
      setVolumeMap(new Map(volumeMap.set(id, average)))
    }, 500);
    return measure
  }


// if video enable during call
useEffect(()=>{
if (myStream.current && isVideoEnabled) {
(async ()=>{
  const videoStream = await getScreenVideo()
  myStream.current.addTrack(videoStream.getVideoTracks()[0])
  console.log(videoStream.getTracks())
  console.log(peers.current, receivedCalls.current)
  peers.current.map((call)=>{
    console.log('peers video', call)
    call.peerConnection.addTrack(videoStream.getVideoTracks()[0])
    console.log('sender', call.peerConnection.getSenders(), call.peerConnection)
  })

  Array.from(receivedCalls.current).map(([u, call])=>{
    call.peerConnection.addTrack(videoStream.getVideoTracks()[0])
  })
  peer.current.disconnect()
  peer.current.reconnect()
})()
}
}, [isVideoEnabled])


useEffect(()=>{
if (isJoined && (!peer.current || peer.current.destroyed) ) {
(async ()=>{
  socket.current.emit('joinVoice');
  let mystream = new MediaStream();
  mystream.addTrack((await getAudio()).getAudioTracks()[0]);
  if (isVideoEnabled) {
    let screenStream = (await getScreenVideo())
    mystream.addTrack(screenStream.getVideoTracks()[0])
    let screenAudioTracks = screenStream.getAudioTracks()
    if (screenAudioTracks.length > 0) {
      mystream.addTrack(screenAudioTracks[0])
    }
  }
  myStream.current = mystream;
  console.log('myStream', myStream.current, myStream.current.getTracks())

  myStream.current.getAudioTracks()[0].enabled = !isMuted;
  setMyMeasureInterval(measureVolume(socket.current.id, mystream))

  peer.current = new Peer(socket.current.id, peerOptions)
  console.log(peer.current);
  if (peer.current.destroyed) {
    peer.current = new Peer(socket.current.id, peerOptions)
    console.log('new peer')
  }

  peer.current.on('open', (id) => {
    setPeerStatus(true);
    console.log("connected to peerserver");

    // won't call myself 
    console.log('im', socket.current.id)
    const otherUsersInVoice = (usersInVoice).filter(x=>x[0] !== socket.current.id);  
    console.log('others in voice', otherUsersInVoice)

    peers.current = (otherUsersInVoice).map((u) => {
      //call everyone already present 
      var mediaConnection = peer.current.call(u[0], myStream.current, peerCallOptions); 
      console.log(`Calling ${u[1].nickname}`, myStream.current.getTracks());

      const audio = document.createElement('audio');
      const video = document.createElement('video');
      console.log(mediaConnection)
      mediaConnection.on('stream', (stream)=>{
        console.log(`${u[1].nickname} picked up call`, stream, stream.getTracks())
        //measureVolume(u[0], stream, stream.getVideoTracks()[0]) //wtf is this
        if (stream.getVideoTracks().length > 0) {
          console.log(u, otherVideos)
          setOtherVideos(new Map(otherVideos).set(u, stream))
        }
        const newStream = new MediaStream();
        newStream.addTrack(stream.getAudioTracks()[0])
        audio.srcObject = newStream;
        audio.addEventListener('loadedmetadata', () => {
          audio.play()
        })
      });
      mediaConnection.on('close',()=>{
        video.remove();
        audio.remove();
      })
      return mediaConnection; 
    });
    console.log('users-peers', otherUsersInVoice, peers)
  });

  peer.current.on('call', (call)=>{
    console.log("call receiving", call, call.peer, usersInVoice, usersInVoice[call.peer])
    const u = usersInVoice[call.peer]
    console.log("mystream", myStream.current, myStream.current.getTracks());
    call.answer(myStream.current); 
    const audio = document.createElement('audio');
    const video = document.createElement('video');
    call.on('stream', (stream)=>{
      console.log('receivedStream', stream, stream.getTracks())
      if (stream.getVideoTracks().length > 0) {
        setOtherVideos(new Map(otherVideos).set(u, stream))
      }
      const newStream = new MediaStream();
      newStream.addTrack(stream.getAudioTracks()[0])
      audio.srcObject = newStream;
      audio.addEventListener('loadedmetadata', () => {
        audio.play()
      })

      receivedCalls.current.set(call.peer, call); 
      console.log('receivedcalls', receivedCalls.current)
      console.log(call.remoteStream, call.remoteStream.getTracks())
      measureVolume(call.peer, stream)
    });

  call.on('close', ()=>{
    video.remove();
    audio.remove();
  })
});
})();
} else if (!isJoined) {
  if (myMeasureInterval) {
    clearInterval(myMeasureInterval)
    setMyMeasureInterval(0)
  }
  if (peer.current) {
    if (receivedCalls.current) {
      Array.from(receivedCalls.current).map(([u, call])=>call.close())
    }
    receivedCalls = new Map()
    peers.current.map((call)=>call.close)
    peers = []
    peer.current.destroy();
    console.log('peer destroying', peer.current);
  }
  setPeerStatus(false)
}}, [isJoined])

const onJoinButton = async ()=>{
  if (isJoined) {
    console.log('isJoined leave voice')
    socket.current.emit('leaveVoice')
  }
  console.log('joining', isJoined)
  setIsJoined(!isJoined)
}

useEffect(()=>{
  if (myStream.current) {
    const track = myStream.current.getAudioTracks()[0];
    track.enabled = !isMuted;
    console.log('muted', track, isMuted)
  }
}, [isMuted])
useEffect(()=>{
    console.log('!!!', Array.from(otherVideos))
}, [otherVideos])


return (
<>
  {!isLoading ?
  <div className='flex flex-col gap-10'>
  <div className='flex flex-col gap-4 p-6 min-w-96 w-fit max-h-fit border-ctp-mauve bg-ctp-crust shadow-md rounded-md'>
    <h1 className='text-2xl text-center leading-6'>Voice Chat</h1>
    <div className='flex justify-between pb-4'>
      <p className='text-lg my-auto'>Соединение: {peerStatus ? '👌' : '❌'}</p>
      <button className='send-btn' onClick={onJoinButton}>
        {isJoined ? 'leave' : 'join'}
      </button>
    </div>
    <div className='flex flex-col gap-4 px-4 min-h-44'>
      {usersInVoice.map((user, idx)=>
        <div className='flex gap-4' key={idx}>
          <img 
            className='w-12 h-12 object-cover rounded-md'
            src={user[1].profile_pic}
            alt='just profile pic'
          />
          <div className='flex flex-1 items-center justify-between'>
            <span className='flex-end text-xl'>{user[1].nickname}</span> 
            {volumeMap.get(user[0]) > 11 && <span className='text-rose-500'>ON AIR</span>}
          </div>
        </div>
      )}
    </div>
    <div className='flex mt-auto justify-between'>
      <button className='send-btn'
        onClick={()=>{setIsMuted(!isMuted)}}
      >{isMuted ? 'unmute': 'mute'}</button>
      <button className='send-btn'
        onClick={()=>{setIsVideoEnabled(!isVideoEnabled)}}
      >{isVideoEnabled ? 'turn off video' : 'turn on video'}</button>
    </div>
  </div>
  <div className='overflow-auto'>
    <div className='min-h-1/3 bg-ctp-base'>
      {(myStream.current && myStream.current.getVideoTracks().length > 0) && (myStream.current.getAudioTracks().length > 1 ? 
        <Video videoTrack={myStream.current.getVideoTracks()[0]} audioTrack={myStream.current.getAudioTracks()[1]} /> : 
        <Video videoTrack={myStream.current.getVideoTracks()[0]} /> )
      }

      {Array.from(otherVideos).map(([u, stream])=>{
        return (
          <div key='{u[0]}'>
            <span>{u && u[1].nickname}</span>
            {(stream && stream.getVideoTracks().length > 0) && (stream.getAudioTracks().length > 1 ? 
              <Video videoTrack={stream.getVideoTracks()[0]} audioTrack={stream.getAudioTracks()[1]} /> : 
              <Video videoTrack={stream.getVideoTracks()[0]} /> )
            }
          </div>
        )
      })}
    </div>
    <div id='videvo'>
    </div>
  </div>
  </div>
  : "loading..."}
</>
);
};

export default VoiceChat
