import React, { useState, useRef } from "react";

import { useAuth } from '../hooks/useAuth'
import { LoginForm } from '../components/LoginForm'
import { RegisterForm } from '../components/RegisterForm'
import * as THREE from 'three';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

const animation = async (ref)=>{
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera( 75, 1, 1, 1000 );
  camera.position.set(550, 500, 0)
  camera.lookAt(0, 50, 0)
  const loader = new FontLoader();
  function loadFont(url) {
    return new Promise((resolve, reject) => {
       loader.load(url, resolve, undefined, reject)
    })
  }
  const loader3d = new GLTFLoader();
  function loadModel(url) {
    return new Promise((resolve, reject) => {
       loader3d.load(url, resolve, undefined, reject)
    })
  }
  const font = await loadFont('idk.typeface.json')
  const material = new THREE.MeshBasicMaterial( { color: 0xbb44ee } );
  const text_array = [
    'сын мияги?\nнет, не пролетал',
    'пустят ли в рай\nс чипсами?',
    'я бы только rape и rape\nrape и rape и warcrime и ss...',
    'я люблю жить и адидас',
    'дьявол носит демикс',
    'души нормисов\nне вознесутся',
    'legalize nuclear bombs',
    'damn i hate being sober'
  ]
  const text = text_array[Math.floor(Math.random() * text_array.length)]
  const textGeometry = new TextGeometry(text, {
    font: font,
    size: 40,
    depth: 20,
    curveSegments: 12,
    bevelEnabled: true,
    bevelThickness: 0.03,
    bevelSize: 0.02,
    bevelOffset: 0,
    bevelSegments: 5,
  })
  textGeometry.center()
  const text_3d = new THREE.Mesh(textGeometry, material)
  scene.add(text_3d)
  text_3d.position.set(0,350,0)
  text_3d.rotation.set(0, 90, 0)

  // lada
  const url_array = ['lada_2107.glb', 'skull.glb']
  const url = url_array[Math.floor(Math.random() * url_array.length)]
  const lada = await loadModel(url)
  lada.scene.scale.set(100,100,100)
  lada.scene.position.set(0,-100,0)
  scene.add(lada.scene);
  const light = new THREE.AmbientLight( 0xffeeff, 3); // soft white light
  scene.add( light );
  const pointLight = new THREE.PointLight(0xff55ff, 150000, 30000);
  pointLight.position.set(100, 200, 100)
  scene.add(pointLight)
  const pointLight1 = new THREE.PointLight(0xff44ff, 150000, 30000);
  pointLight1.position.set(-50, 200, -50)
  scene.add(pointLight1)

  camera.position.z = 5;
  const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
  renderer.setSize( ref.current.clientWidth,  ref.current.clientHeight-5);
  renderer.setPixelRatio(window.devicePixelRatio);
  ref.current.appendChild( renderer.domElement );
  function rotateSmth(thing) {
    thing.rotation.x += 0.01;
    thing.rotation.y += 0.01;
    thing.rotation.z += 0.01;
  }
  function animate() {
    renderer.render( scene, camera );
    rotateSmth(lada.scene)
    text_3d.rotation.y += 0.015;
  }
  renderer.setAnimationLoop( animate );
}

export const LoginPage = () => {
  const anim = useRef(null);
  animation(anim)


  const array = ['https://discord.ru'];
  const RussianRullete = () => {
    const randLink = array[Math.floor(Math.random() * array.length)];
    window.location = randLink;
  };
  return (
    <>
      <div className='flex flex-col h-full overflow-y-auto overflow-x-hidden pt-8'>
        <div className='flex m-auto max-w-screen-xl gap-12'>
          <div className='flex flex-col gap-6'>
            <h2 className='text-4xl font-bold m-auto text-white'>(* ^ ω ^) САЙT В(о_О)JlШебниЦ</h2>
            <div className="flex gap-6 w-full mx-auto">
              <LoginForm/>
              <RegisterForm/>
            </div>
            <button
              onClick={RussianRullete}
              className='bg-gradient-to-r from-ctp-mauve to-ctp-maroon px-auto py-4 rounded-md hover:cursor-pointer hover:text-ctp-red hover:scale-[.98] transition-all duration-500'
            >
              <h2 className='text-2xl text-white font-bold leading-6 vertical-top'>
                Русская рулетка крутить бесплатно ТЫК
              </h2>
            </button>
          </div>
          <div className="flex justify-center m-auto"><iframe src="https://www.youtube.com/embed/MTLM8wgscaM?yt:stretch=16:9&vq=hd480&autoplay=1&loop=1&color=red&mute=1&iv_load_policy=3&rel=0&autohide=1&controls=0" width="225" height="463" allowtransparency="true" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" alt="Видео"></iframe></div>
        </div>
        <div ref={anim} className='flex flex-1 mx-auto w-full max-w-4xl min-h-96 mt-4'>
        </div>
      </div>
      <div>
      </div>
    </>
  )

}

export default LoginPage;
