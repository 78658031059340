import React from "react";

export const Message = ({msg}) => {
  const {nickname, profile_pic} = msg.author;
  return (
    <>
      <div className='w-full my-2'>
        <div className='flex w-full'>
          <img 
            className='w-16 h-16 object-cover rounded-md'
            src={profile_pic}
            alt='just profile pic'
          />
          <div className='flex-1 mx-4 border-b-2 border-ctp-mauve'>
            <span className='block text-lg'>{nickname}</span> 
            <span className='block text-ctp-subtext0 text-sm'>
              {msg.created_at}
            </span>
          </div>
        </div>
        <p className='p-2 break-all'>{msg.text}</p>
      </div>
    </>
  );
};

export default Message
