import React from "react";

import Message from '../components/Message'

export const ChatInList= ({chat, setSelectedChat, allMessages}) => {
  const {title, cover} = chat;
  let lastMsg = null;
  if (allMessages.get(chat.id) && allMessages.get(chat.id).at(-1)) {
    lastMsg = allMessages.get(chat.id).at(-1)
  }

  return (
    <div
      className='
        flex w-full bg-ctp-base rounded-md px-4 shadow-md outline-2 
        outline-offset-2 outline-ctp-mauve hover:cursor-pointer transition 
        hover:shadow-md duration-500
      '
      onClick={() => {setSelectedChat(chat)}}
    >
      <img
        src={cover}
        className='
          object-cover w-32 h-32 rounded-3xl my-4 mr-2 border-ctp-mantle
        '
        alt="chat pic"
      />
      <div className='flex flex-col w-full'>
        <div className='text-center w-full p-2'>
          <h2 className='text-2xl border-b-2 border-ctp-mauve p-2'>{title}</h2>
        </div>
        <div className='px-4'>
          {lastMsg && <Message msg={lastMsg}/>}
        </div>
      </div>
    </div>
  );
};
export default ChatInList;
