export const get_chat_list = async (token) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  };
  const response = await fetch('/api/chats/list', requestOptions);
  return response;
};

export const create_chat = async (token, chatTitle) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body : JSON.stringify({title: chatTitle})
  };
  const response = await fetch('/api/chats', requestOptions);
  return response;
};

export const get_chat_messages = async (token, chatId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  };
  const response = await fetch(`/api/chats/${chatId}/messages`, requestOptions);
  return response;
};

export const get_chat_members = async (token, chatId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  };
  const response = await fetch(`/api/chats/${chatId}/members`, requestOptions);
  return response;
};

export const add_member_to_chat = async (token, chatId, nickname) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  };
  const encoded_nick = encodeURIComponent(nickname);
  const response = await fetch(`/api/chats/${chatId}/add_member/${encoded_nick}`, requestOptions);
  return response;
};

export const kick_member_from_chat = async (token, chatId, nickname) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  };
  const response = await fetch(`/api/chats/${chatId}/kick_member/${nickname}`, requestOptions);
  return response;
};

export const get_role = async (token, chatId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  };
  const response = await fetch(`/api/chats/${chatId}/my_role`, requestOptions);
  return response;
}

export const format_msg_time = (msg) => {
    if (msg.created_at) {
      msg.created_at = Intl.DateTimeFormat(
        "ru", {dateStyle: "short", timeStyle: "short"}
      ).format(new Date(msg.created_at));
    }
  };

export async function updateChat(token, id, update) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(update),
  };

  const response = await fetch(`/api/chats/${id}/update`, requestOptions);
  return response;
};
