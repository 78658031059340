import React, { useState, useEffect, useRef } from "react";
import { io } from 'socket.io-client'
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { ErrorMessage } from '../components/ErrorMessage'
import { useAuth } from '../hooks/useAuth'
import { get_chat_list, create_chat, format_msg_time } from '../utils/chatService'
import { ChatPage } from './ChatPage'
import ChatInList from '../components/ChatInList'
import Message from '../components/Message'
import VoiceChat from '../components/Chat'


export const ChatSelectionPage = ( {allMessages, setAllMessages, socket} ) => {
  const { user, logout } = useAuth();
  const [errorMessage, setErrorMessage] = useState();
  const [chatList, setChatList] = useState([]);
  const [userChats, setUserChats] = useState(new Map());
  const [chatTitle, setChatTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedChat, setSelectedChat] = useState();

  const render_chats = async () => {
    const response = await get_chat_list(user.access_token);
    const body = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        logout();
      }
      setErrorMessage(body.detail);
    } else {
      body.map((chat) => {
	if (chat.last_message) {
	  format_msg_time(chat.last_message)
	}
        userChats.set(chat.id, chat);
        setAllMessages(
          (prevMap) => new Map(prevMap.set(chat.id, [chat.last_message]))
        )
      })
      setIsLoading(false);
    };
  };


  useEffect(() => {
    (async () => {
      await render_chats();
    })();
  }, []);

  const handleChatCreation = async (e) => {
    e.preventDefault();
    const response = await create_chat(user.access_token, chatTitle);
    const body = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        logout();
      }
      setErrorMessage(body.detail);
    } else {
      await render_chats(); 
    };
  };

  return (
    <>
    {selectedChat ? (
      <ChatPage 
        chat={selectedChat}
        setSelectedChat={setSelectedChat}
        allMessages={allMessages}
        setAllMessages={setAllMessages}
        socket={socket}
      />
    ) : (
      <>
        <form onSubmit={handleChatCreation} className=''>
          <h1 className='text-3xl mb-4 text-center'>Чаты</h1>
          <div className='flex gap-2'>
            <input
              placeholder='Название'
              value={chatTitle}
              onChange={(e) => setChatTitle(e.target.value)}
              className='w-3/4'
              required
            />
            <button className='send-btn w-1/4 px-4' type='submit'>Создать</button>
          </div>
        </form>
        <div>
          <ErrorMessage message={errorMessage} />
        </div>

        <div className='flex flex-col gap-6 mt-10 overflow-y-auto max-h-[70dvh]'>
          {(isLoading && (userChats.size > 0)) ? (
            <div>loading...</div>
          ) : (
            Array.from(userChats.entries()).map(([key, value]) =>
              <ChatInList
                key={key}
                chat={value}
                setSelectedChat={setSelectedChat}
                allMessages={allMessages}
              />
            )
          )}
        </div>
      </>
    )}
    </>
  );
}


export const LoggedMainPage = () => {
  const { user, logout } = useAuth();
  const socket = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [allMessages, setAllMessages] = useState(new Map());
  const [usersInVoice, setUsersInVoice] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState();

  useEffect(()=>{
    socket.current = io(
      { transports: ['websocket'], path: '/sockets', auth: user.access_token }
    )
    socket.current.on('connect', ()=>{
      setIsLoading(false)
      console.log('socket', socket.current.connected)
    })
    socket.current.on('connect_error', err => {
      if (err.message == 'auth failed') logout()
    })
    socket.current.on('new_message', msg => {
      msg = JSON.parse(msg)
      format_msg_time(msg);
      setArrivalMessage(msg);
      console.log(msg)
    })
    socket.current.on('addUsersInVoice', (users) => {
      console.log('socket add users', users)
      setUsersInVoice(prev=>users)
    });
    socket.current.on('addUserInVoice', (user) => {
      console.log('socket add user', user)
      setUsersInVoice(prev=>[...prev, user])
    });
    socket.current.on('removeUserInVoice',(user)=>{
      setUsersInVoice(
        usersInVoice=>usersInVoice.filter(x=>x[1].nickname !== user.nickname)
      ); 
    });
  }, [])

  useEffect(()=>{
    if (arrivalMessage) {
      const chatHistory = allMessages.get(arrivalMessage.chat_id)
      setAllMessages((prevMap) => new Map(
        prevMap.set(arrivalMessage.chat_id, [...chatHistory, arrivalMessage])
      ))
    }
  }, [arrivalMessage])


  return (
    <>
      {isLoading ? (
        <div>Connecting...</div>
      ) : (
        <div className='flex justify-between'>
          <div className='w-1/2'>
            <ChatSelectionPage
              allMessages={allMessages}
              setAllMessages={setAllMessages}
              socket={socket}
            />
          </div>
          <div className='w-1/3'>
            <VoiceChat usersInVoice={usersInVoice} socket={socket} />
          </div>
        </div>
      )}
    </>
  );
};


export const MainPage = () => {
  const { user } = useAuth();
  const array = ['https://discord.ru'];
  const RussianRullete = () => {
    const randLink = array[Math.floor(Math.random() * array.length)];
    window.location = randLink;
  };

  return (
    <>
    {user ? (
      <LoggedMainPage />
    ) : (
      <>
        <button
          onClick={RussianRullete}
          className='text-5xl border-ctp-lavender m-auto py-6'
        >
          <h2 className='text-gradient from-ctp-flamingo to-ctp-pink font-bold'>
            Русская рулетка крутить бесплатно ТЫК
          </h2>
        </button>
      </>
    )}
    </>
  );
};

export default MainPage;
