import React, { useState, useEffect, useRef, useReducer } from "react";

import VoiceChat from "../components/VoiceChat"
import TextChat from '../components/TextChat';

export const ChatPage = ({
  usersInVoice, socket, isLoading, myStream,
  peer, peers, receivedCalls, peerStatus, setPeerStatus, isJoined, setIsJoined, otherVideos, setOtherVideos,
  chat, setSelectedChat, allMessages, setAllMessages
}) => {
  return (
    <>
    <div className='flex gap-10 justify-between overflow-auto'>
      <VoiceChat usersInVoice={usersInVoice} socket={socket} isLoading={isLoading} myStream={myStream} peer={peer} peers={peers} receivedCalls={receivedCalls} peerStatus={peerStatus} setPeerStatus={setPeerStatus} isJoined={isJoined} setIsJoined={setIsJoined} otherVideos={otherVideos} setOtherVideos={setOtherVideos}/>
      <TextChat chat={chat} socket={socket} allMessages={allMessages} setAllMessages={setAllMessages}/>
    </div>
    </>
  );
}

export default ChatPage;
